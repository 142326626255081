import React from 'react';
import "../css/page css/GalleryPage.css";
import AppointmentBooking from '../components/AppointmentBooking';
import ImageFolder from '../components/ImageFolder';
const galleryCollage = require("../images/gallery page collage.png");
const g1 = require("../images/g1.jpeg");
const g2 = require("../images/RICE FLOUR - JUL-2024/j4.jpeg");

export default function GalleryPage() {
  return (
    <div className="gallery-page">
      <div className="gallery-page-landing-section">
        <div className="gallery-page-landing-section-left">
          <h3>
            Take A Look at Collection of <span>Our memories</span>{" "}
          </h3>
          <p>
            We proudly showcase a comprehensive gallery of photos depicting our
            successful export and import operations. These images serve as a
            visual testament to our expertise and reliability in international
            trade, demonstrating the breadth of products and regions we cover.
            They highlight our commitment to seamless logistics, adherence to
            regulatory standards, and our dedication to client satisfaction.
            Whether it's showcasing the delivery of specialized goods or
            navigating complex customs procedures, these photos illustrate our
            capability to handle diverse challenges and ensure smooth
            transactions.
          </p>
        </div>
        <div className="gallery-page-landing-section-right">
          <img src={galleryCollage} alt="Gallery Collage" />
        </div>
      </div>
      <div className="gallery-page-image-section">
        <ImageFolder
          thumbnail={g1}
          date="Machinery Spares - Mar-2024"
          link="/gallery_01072047xSJK23"
        />
        <ImageFolder
          thumbnail={g2}
          date="RICE FLOUR - JUL-2024"
          link="/gallery_04072047xSJK23"
        />
      </div>

      <div className="book-an-appointment-section">
        <h3>
          Book An <span>Appointment</span>
        </h3>
        <p>
          Questions? Need assistance? Our dedicated support team is here to help
          you every step of the way:
        </p>
        <AppointmentBooking />
      </div>
    </div>
  );
}
