import React from 'react';
import "../css/page css/CertificatePage.css";
import CertificateBox from '../components/CertificateBox';
import AppointmentBooking from '../components/AppointmentBooking';
const certificate1 = require("../images/c1.jpg");
const certificate2 = require("../images/c2.jpg");
const certificate3 = require("../images/c4.png");
const certificate4 = require("../images/c3.png");
const certificate5 = require("../images/c5.png");

const pdf1 = require("../pdf/certificate1.pdf");
const pdf2 = require("../pdf/certificate2.pdf");
const pdf3 = require("../pdf/MSME.pdf");
const pdf4 = require("../pdf/APEDA.pdf");
const pdf5 = require("../pdf/FSSAI.pdf");

const CertificatePage: React.FC = () => {
  return (
    <div className="certificate-page">
      <div className="certificate-page-landing-section">
        <h1>Certificates</h1>
        <h3>Glimpses of the honor we received</h3>
      </div>
      <div className="certificate-page-certificate-section">
        <CertificateBox image={certificate1} name="GST" url={pdf1} />
        <CertificateBox image={certificate2} name="IEC" url={pdf2} />
        <CertificateBox image={certificate3} name="MSME" url={pdf3} />
        <CertificateBox image={certificate4} name="APEDA" url={pdf4} />
        <CertificateBox image={certificate5} name="FSSAI" url={pdf5} />
      </div>
      <div className="book-an-appointment-section">
        <h3>
          Book An <span>Appointment</span>
        </h3>
        <p>
          Questions? Need assistance? Our dedicated support team is here to help
          you every step of the way:
        </p>
        <AppointmentBooking />
      </div>
    </div>
  );
};

export default CertificatePage;
