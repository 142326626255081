import React from 'react';
import "../css/component css/Footer.css";
import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
const logo  = require("../images/bg logo.png");

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <div className="footer-top">
        <div className="footer-cols">
          <img src={logo} alt="Logo" />
        </div>
        <div className="footer-cols">
          <h5>Connect With Us</h5>
          <div className="flex-icon-box">
            <EmailIcon className="icon" />
            <a
              href="mailto:absodokpvtltd@gmail.com"
              aria-label="mail us"
              target="_blank"
              rel="noopener noreferrer"
            >
              absodokpvtltd@gmail.com
            </a>
          </div>
          <div className="flex-icon-box">
            <PersonIcon className="icon" />
            <a href="tel:9444808674" aria-label="Call Us">
              +91-9444808674
            </a>
          </div>
        </div>
        <div className="footer-cols">
          <h5>Important Links</h5>
          <Link to="/">Home</Link>
          <Link to="/gallery">Gallery</Link>
          <Link to="/certificate">Certificates</Link>
          <Link to="/product-sourcing">Product Sourcing</Link>
        </div>
        <div className="footer-cols">
          <h5>Visit Us</h5>
          <div className="flex-icon-box">
            <LocationOnIcon className="icon" />
            <p>
              20/7, Virtua Hub, SBI Colony, city link road, Adambakkam,
              Chennai-600088
            </p>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d15550.92759299756!2d80.18357631917496!3d12.988992056084278!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s20%2F7%2C%20Virtua%20Hub%2C%20SBI%20Colony%2C%20citi%20link%20road%2C%20Adambakkam%2C%20Chennai%20-600088!5e0!3m2!1sen!2sin!4v1720365947782!5m2!1sen!2sin"
            className="gmap"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google map"
          ></iframe>
        </div>
      </div>
      <div className="footer-middle"></div>
      <div className="footer-bottom">
        <div className="footer-bottom-left">
          <a href="#" aria-label="go to facebook">
            <FacebookIcon className="social-icon" />
          </a>
          <a href="#" aria-label="go to instagram">
            <InstagramIcon className="social-icon" />
          </a>
          <a href="#" aria-label="go to linkedin">
            <LinkedInIcon className="social-icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
