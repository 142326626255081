import React from 'react'
import "../css/component css/Table.css"
export default function Table() {
  return (
    <>
        <table>
        <tr>
            <th colSpan={2} className='table-heading'>Pro Plan Service Rate</th>
        </tr>
        <tr>
            <th>Product Value</th>
            <th>Service Fee</th>
        </tr>
        <tr>
            <td>&lt; $1000</td>
            <td>$100</td>
        </tr>
        <tr>
            <td>$1000 - $3000</td>
            <td>9%</td>
        </tr>
        <tr>
            <td>$3000 - $5000</td>
            <td>8%</td>
        </tr>
        <tr>
            <td>$5000 - $7000</td>
            <td>7%</td>
        </tr>
        <tr>
            <td>$7000 - $10,000</td>
            <td>6%</td>
        </tr>
        <tr>
            <td>$10,000</td>
            <td>5%</td>
        </tr>
    </table>
    </>
  )
}
