import React from 'react';
import "../css/page css/ProductSourcingPage.css";
import AppointmentBooking from '../components/AppointmentBooking';
import PricingSection from '../components/PricingSection';
import NumberedContent from '../components/NumberedContent';
import SourcingService from '../components/SourcingService';
import OurPricing from '../components/OurPricing';
const fifthSectionImg = require("../images/ps fifth section img.png");
const shipImg = require("../images/certificate section img.png");
const serviceSectionImage = require("../images/about section img.png");
const pricingTable = require("../images/pricing table.png");
const logo = require("../images/bg logo.png");


const icon1 = require("../icons/product-sourcing.png");
const icon2 = require("../icons/supplier-sourcing.png");
const icon3 = require("../icons/contract-management.png");
const icon4 = require("../icons/quality-inspection.png");
const icon5 = require("../icons/warehouse.png");
const icon6 = require("../icons/shipping.png");
const icon7 = require("../icons/photography.png");
const icon8 = require("../icons/amazon.png");
const icon9 = require("../icons/dropshipping.png");


export default function ProductSourcingPage() {
  return (
    <div className='product-sourcing-page'>
      <div className="product-sourcing-landing-section">
        <h3>Get Ahead in <span>Global Trade</span> with Our Reliable Product Sourcing Solutions</h3>
        <a href="#psSecondSection" aria-label='go to next section' className="btn-a orange-btn">Explore Now</a>
      </div>

      <div className="ps-second-section" id='psSecondSection'>
        <div className="ps-second-section-left">
          <img src={logo} alt="Background Logo" />
        </div>
        <div className="ps-second-section-right">
          <p>Looking to source, manufacture, inspect, or ship your next product from China? Whether this is your first or your 10,000th product, we’re here to help with some “small” steps include:</p>
          <ul>
            <li>Product Sourcing</li>
            <li>Quality Control</li>
            <li>Prep Service</li>
            <li>Shipping Solutions</li>
          </ul>
          <p>Our team is dedicated to finding the best suppliers and manufacturers for your specific needs. We take the time to research and vet each potential partner to ensure they meet our strict criteria for quality, reliability, and ethical practices.</p>
        </div>
      </div>

      <div className="service-section">
        <div className="service-section-top">
          <h3><span>China</span> Sourcing Services For You</h3>
          <p>From Helping You Source The Right Supplier To Deliver Products To Your Doorstep, Absodok Removes China Sourcing Complexity!</p>
        </div>
        <div className="service-section-bottom">
          <div className="service-section-bottom-left">
            <NumberedContent counting={1} title="Have Problems When Sourcing From China?" description="Absodok is your team in China to help create hassle-free Chinese sourcing, allowing you to have more time to focus on sales in your local market."/>
            <NumberedContent counting={2} title="Can't Communicate Fluently?" description="No longer worried about language barriers. Our sourcing specialists are fluent in both Mandarin and Cantonese, which makes it very convenient for you to source in Guangzhou and other cities in China."/>
            <NumberedContent counting={3} title="Can't Understand Chinese Culture?" description="Although you may be able to communicate with your Chinese vendors in fluent English, our sourcing specialists are your best help to avoid any misunderstandings caused by indirect and implicit messages."/>
            <NumberedContent counting={4} title="Not Sure If The Supplier Is Trustworthy?" description="Through our well-established supplier sourcing and factory audit process, from factory background to production capacity and quality, we can ensure that you only work with the most reputable suppliers."/>
            <NumberedContent counting={5} title="Can’t Know The Production Schedule Accurately?" description="Factories have too many orders to deal with and may not be able to report the production progress to you in time. But Absodok can regularly help you check the production on-site to ensure a short lead time."/>
            <NumberedContent counting={6} title="Can't Handle Customs Affairs Smoothly?" description="With our years of experience in China’s import and export, you can rest assured that you can entrust logistics, customs, and other matters to us. Just contact our team and wait for the goods to arrive"/>
          </div>
          <div className="service-section-bottom-right">
            <img src={serviceSectionImage} alt="Service Section Image" />
          </div>
        </div>
      </div>

      <div className="ps-forth-section">
        <h3><span>Sourcing Agent</span> – Sourcing, Buying, Shipping And Exporting</h3>
        <p>Our Expertise in sourcing, Absodok Sourcing designs customized supply chain solutions for startup companies. We source, oversee production, and deliver goods in demand from consumers around the world.</p>
        <h4>We <span>Love</span> All Types Of Customers</h4>
        <p>We embrace diversity in our client base, catering to the unique needs of each entity, regardless of its scale. From small startups taking their initial steps in the industry to large corporations seeking innovative solutions, our services are meticulously tailored to ensure that every client receives exceptional support and expertise.</p>
        <p>PRICING TRANSPARENCY We Don’t Add Any Middle-Man Margins. <span>Free Consultation</span></p>
        <h5>We Do The Hard Work So You Don't Have To!</h5>
      </div>

      <div className="ps-fifth-section">
        <h3>We can save your time and money by strategic sourcing & Procurement as a critical business function</h3>
        <p>Creating value through sound and profitable strategic sourcing is as essential for our clients as ensuring sales volumes and brand image.</p>
        <p>This is why Absodok Sourcing delivers services that greatly benefit International Buyers and Brands. Our mission as a sourcing agent involves implementing strategic sourcing processes both at tactical and operational levels. Partnering with clients to maintain a competitive advantage, reach goals while keeping sourcing spend in check.</p>
        <p>Absodok Sourcing established its authority as one of the leading sourcing agents in China, Europe, and India over the last decade. In order to achieve substantial cost savings, Absodok Sourcing assists international purchasing teams, at operational and managing levels.</p>
        <p>Information accuracy leads to better business decisions. Our team has proven its ability to cut on inefficiencies thanks to its ability to obtain accurate data on material cost, manufacturing cost, shipping cost & flawed processes.</p>
        <img src={fifthSectionImg} alt="Fifth Section Image" />
      </div>

      <div className="ps-sixth-section">
        <div className="ps-sixth-section-top">
          <h3>Our One-Stop <span>Sourcing Services</span> Internationally</h3>
          <p>At Absodok, we make importing simple. By working with us you will get these services and benefits:</p>
        </div>
        <div className="ps-sixth-section-bottom">
          <SourcingService icon={icon1} title="Product Sourcing" description="Running into problems when locating products and importing Overseas? Outsource your product sourcing project and we can help get it quickly done. Absodok is your reliable sourcing agent in China."/>
          <SourcingService icon={icon2} title="Supplier Sourcing" description="With the assistance of our capable staff at Absodok you can have the finest supplier to design and produce the products you need. We help you effectively fulfill your project planning objectives and deadlines."/>
          <SourcingService icon={icon3} title="Negotiation & Contract Management" description="Do you find it difficult to communicate with suppliers and worry about the potential risks of the contract? Our contract negotiation experts can assist you in navigating the challenges of contracting and negotiations"/>
          <SourcingService icon={icon4} title="Quality Inspection" description="You don’t have to be concerned about product quality before shipment since we can assist you with product quality inspection to ensure a smooth delivery."/>
          <SourcingService icon={icon5} title="Warehouse & Storage" description="Many Chinese vendors do not support storage services. You can have your products stored at our warehouse for 60 days with no fees to make your transport more flexible."/>
          <SourcingService icon={icon6} title="Shipping" description="Our firm specializes in shipment coordination. We will process your order as soon as we get it from factories, oversee each stage, and dispatch your order as quickly as possible and properly."/>
          <SourcingService icon={icon7} title="Product Photography" description="To attract your target audience members who like to shop online, you must also provide clear, eye-catching images of your items. We make it efficient for you by providing you with amazing product photos."/>
          <SourcingService icon={icon8} title="Amazon FBA prep" description="We can provide Amazon FBA prep services for Amazon sellers with different aiding services. Work with us to ease up the process of sourcing products from Chinese manufacturers to sell on Amazon."/>
          <SourcingService icon={icon9} title="Dropshipping" description="We will provide the best drop shipping service with high-quality products at a reasonable cost. To assure a lower price, we will buy quality items directly from producers rather than through intermediaries."/>
        </div>
      </div>

      <PricingSection image={shipImg} h3="Transparent Sourcing" span="Agent Fees" p="In our business, we uphold transparency by offering clear and straightforward sourcing agent fees. At Absodok Private limited, we prioritize openness in our pricing structure for sourcing services. Our clients benefit from knowing upfront the costs associated with our expert procurement and sourcing efforts." p2="This commitment to transparency builds trust and ensures that our clients receive value-driven services without hidden charges, empowering them to make informed decisions and optimize their supply chain operations effectively." rowReverse="rowReverse"/>
      {/* <PricingSection image={pricingTable} h3="Our" span="Pricing" p="We apply a service fee calculated as a percentage of the total product value of your order." p2="" rowReverse=""/> */}
      <OurPricing/>

      <div className="ps-seventh-section">
        <h3>Sourcing Agent <span>Supports</span> All Business</h3>
        <div className="ps-seventh-section-middle">
          <div className="sourcing-agent-card">
            <h4>E-Commerce</h4>
            <p>Absodok is ideal for all sizes of e-commerce. Suitable for major platforms like eBay, Amazon, Shopify, and other well-known sites. We will assist you in saving time, lowering total costs, avoiding frauds, and obtaining high-quality items with no effort.</p>
          </div>
          <div className="sourcing-agent-card">
            <h4>Small/Start-Up Business</h4>
            <p>A sourcing agency may be worth considering if you are a small firm or entrepreneur wanting to source from China. We help find the right products at a factory-direct price and with guaranteed quality, which is hard for you to handle in China.</p>
          </div>
          <div className="sourcing-agent-card">
            <h4>Bulk Orders</h4>
            <p>We have skilled agents and provide one-on-one assistance to guarantee that your bulk orders are delivered on time by taking care of all details of your order from beginning to end.</p>
          </div>
          <div className="sourcing-agent-card">
            <h4>Quality Assurance At Absodok</h4>
            <p>Absodok is aware of your unique requirements and quality standards, as well as the local business culture. We are committed to providing our customers with the finest quality and total satisfaction.</p>
          </div>
        </div>
        <p>We assess more information about the plant to guarantee that the collaboration goes smoothly. We can assist you with supplier/vendor audits, on-site factory inspections, and factory strength verification.<br/>
        If you are unsure about the quality of the items in the factory, our product quality check may dispel your concerns and guarantee that the goods fulfill your specifications.</p>
      </div>

      <div className="ps-eighth-section">
        <h3>Benefits of <span>ABSODOK</span></h3>
        <div className="benefit-section">
          <NumberedContent counting={1} title="Two-Day Sourcing Results" description="Send us an inquiry on the products you need and a sourcing expert will get back to you on the same day and get you the sourcing results within 2 days."/>
          <NumberedContent counting={2} title="Committed Client Support" description="Customer satisfaction is a number one priority. We are dedicated to providing world-class sourcing service, listening to customer requests, and being reachable at all times"/>

          <NumberedContent counting={3} title="30-Day Money Back Guarantee" description="After experiencing our processes, people, and service, if you are not satisfied with what we did for you, we will refund you the service fee."/>
          <NumberedContent counting={4} title="Hands-Off Sourcing" description="We understand the importance of getting your orders right the first time, every time. We guarantee excellence in our core competencies, while you execute excellence in yours. "/>

          <NumberedContent counting={5} title="Customized For You" description="If you have unique needs, we can accommodate your business accordingly. We tailor your chosen solution to be sure it meets your needs. All quotes are customized for each customer. "/>
          <NumberedContent counting={6} title="Business Partnership" description="Absodok is more than a sourcing company. We partner with you, design a custom solution, and execute the sourcing process for your business."/>
        </div>
      </div>

      <div className="ninth-section">
        <div className="ninth-section-box">
          <h4>You Deserve One Of Our Best <span>Sourcing Specialists</span>.</h4>
          <h5>We Solve Real Problems</h5>
          <a href="https://wa.me/9444808674" aria-label='connect on whatsapp' className="btn-a blue-btn">Start Free Sourcing</a>
        </div>
      </div>

      <div className="book-an-appointment-section">
        <h3>Book An <span>Appointment</span></h3>
        <p>Questions? Need assistance? Our dedicated support team is here to help you every step of the way:</p>
        <AppointmentBooking/>
      </div>
    </div>
  );
}
