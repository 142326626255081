import React from 'react';
import "../css/component css/PricingSection.css";
const pricingTable  = require("../images/pricing table.png");

interface PricingSectionProps {
  image: string;
  h3: string;
  span: string;
  p: string;
  p2: string;
  rowReverse?: string;
}

const PricingSection: React.FC<PricingSectionProps> = ({ image, h3, span, p, p2, rowReverse }) => {
  return (
    <div className={`pricing-section ${rowReverse}`}>
      <div className="pricing-section-left">
        <img src={image} alt="Pricing Table" />
      </div>
      <div className="pricing-section-right">
        <h3>{h3}<span> {span}</span></h3>
        <p>{p}</p>
        <p>{p2}</p>
      </div>
    </div>
  );
}

export default PricingSection;
