import React from 'react';
import "../css/component css/ImageFolder.css";
import { Link } from 'react-router-dom';

interface ImageFolderProps {
  thumbnail: string;
  date: string;
  link:string;
}

const ImageFolder: React.FC<ImageFolderProps> = (props) => {
  return (
    <div className="image-folder">
      <img src={props.thumbnail} alt={`Thumbnail for ${props.date}`} />
      <h6>{props.date}</h6>
      <Link to={`${props.link}`} className="btn-a orange-btn">
        View All
      </Link>
    </div>
  );
}

export default ImageFolder;
