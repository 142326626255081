import React from 'react'
import Table from './Table'

export default function OurPricing() {
  return (
    <div className='pricing-section'>
      <div className="pricing-section-left">
        <Table/>
      </div>
      <div className="pricing-section-right">
      <h3>Our<span> Pricing</span></h3>
      <p>We apply a service fee calculated as a percentage of the total product value of your order.</p>
      </div>
    </div>
  )
}
