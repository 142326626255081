import React from 'react';
import "../css/component css/SourcingService.css";

interface SourcingServiceProps {
  icon: string;
  title: string;
  description: string;
}

const SourcingService: React.FC<SourcingServiceProps> = ({ icon, title, description }) => {
  return (
    <div className='sourcing-service'>
      <img src={icon} alt={`${title} Icon`} />
      <h4>{title}</h4>
      <p>{description}</p>
      <a href="https://wa.me/9444808674" aria-label='contact us' target='_blank' className="btn-a orange-btn">Contact Us</a>
    </div>
  );
}

export default SourcingService;
