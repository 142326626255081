import React from 'react';
import "../css/component css/DivisionCard.css";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

interface DivisionCardProps {
  image: string;
  title: string;
  description: string;
}

const DivisionCard: React.FC<DivisionCardProps> = ({ image, title, description }) => {
  return (
    <div className='division-card'>
      <img src={image} alt={title} />
      <div className="division-card-icon">
        <AllInclusiveIcon className='dc-icon' />
      </div>
      <h6>{title}</h6>
      <p>{description}</p>
    </div>
  );
};

export default DivisionCard;
