import React from 'react';
import "../css/component css/NumberedContent.css";

interface NumberedContentProps {
  counting: number | string;
  title: string;
  description: string;
}

const NumberedContent: React.FC<NumberedContentProps> = (props) => {
  return (
    <div className="service-section-content">
      <div className="service-section-content-top">
        <div className="count-round-box">
          <h4>{props.counting}</h4>
        </div>
        <h4>{props.title}</h4>
      </div>
      <div className="service-section-content-bottom">
        <p>{props.description}</p>
      </div>
    </div>
  );
};

export default NumberedContent;
