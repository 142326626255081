import React from 'react';
import "../css/page css/Homepage.css";
import AppointmentBooking from '../components/AppointmentBooking';
import DivisionCard from '../components/DivisionCard';
import PricingSection from '../components/PricingSection';
import NumberedContent from '../components/NumberedContent';
import { Link } from 'react-router-dom';
import Carousel from "../components/Carousel"
import Table from '../components/Table';
import OurPricing from '../components/OurPricing';
const landingImg = require("../images/homepage landing img.png");
const aboutSectionImg = require("../images/about section img.png");
const pricingTable = require("../images/pricing table.png");
const curvedLine = require("../images/curved line.png");
const serviceSectionImage = require("../images/service section img.png");
const metalScrap = require("../images/metal scrap.jpg");
const pvcResin = require("../images/pvc resin.jpg");
const agriculture = require("../images/agriculture.jpg");

const foodProduct = require("../images/agriculture.jpg");
const perishableProduct = require("../images/perishable product.jpg");
const nonPerishableProduct = require("../images/non-perishable products.jpg");

const certificateSectionImg = require("../images/certificate section img.png");

interface HomepageProps {
}

const Homepage: React.FC<HomepageProps> = () => {
  return (
    <div className='landing-page'>
       
        <div className="landing-section">
          <img src={curvedLine} className='curved-line' alt="" />
          <div className="landing-section-left">
            <h2>Your <span> trusted partner</span> in import/export.</h2>
            <p>At Absodok, we prioritize seamless trade relationships, exceeding customer expectations through our extensive network, stringent quality standards, and personalized service. As an NVOCC, we offer versatile logistics solutions. We address client and manufacturer needs directly, ensuring smooth import/export processes, saving time and money. Our uniqueness lies in our ability to source top-quality products at competitive costs in any market.</p>
            <a href="#aboutSection" aria-label='go to about section' className="btn-a blue-btn">Explore Now</a>
          </div>
          <div className="landing-section-right">
            <img src={landingImg} alt="" />
          </div>
        </div>
        <AppointmentBooking/>
        <div className="about-section" id='aboutSection'>
          <div className="about-section-left">
            <img src={aboutSectionImg} alt="" />
          </div>
          <div className="about-section-right">
            <h3>Get To Know Us</h3>
            <p>Absodok excels in import-export services, forging connections across India, Europe, China, the Middle East, and East and Southeast Asia. We specialize in sourcing and procuring a diverse range of products for manufacturers, retailers, and businesses worldwide. Our unwavering commitment to excellence ensures seamless transactions and superior customer satisfaction. Leveraging our extensive global network and offering tailored solutions, we deliver high-quality products at competitive prices, meeting your specific needs with utmost reliability and precision.</p>
            <p>Partner with us to streamline your supply chain and enhance your business success. Trust us as your dependable partner in navigating the complexities of international trade and achieving your strategic goals.</p>
            <Link to="/product-sourcing" className="btn-a blue-btn">Learn more about us</Link>
          </div>
        </div>


        <div className="division-section">
          <h3><span>Import</span> Division</h3>
          <div className="division-row">
            <DivisionCard image={metalScrap} title="Metal Scraps and Ingots" description="We offer a wide range of metal scraps and ingots sourced from trusted suppliers globally. Whether you're looking for ferrous or non-ferrous metals, we have you covered with high-quality products suitable for various industrial applications."/>
            <DivisionCard image={pvcResin} title="PVC resin" description="we specialize in importing high-quality PVC resin for businesses worldwide. Leveraging our extensive network and market expertise, we ensure timely delivery and competitive pricing. Trust us to meet your PVC resin needs with reliability and precision, supporting your manufacturing and production processes seamlessly."/>
            <DivisionCard image={agriculture} title="Agricultural Machinery Spare Parts" description="Enhance the efficiency and longevity of your agricultural machinery with our premium spare parts. From tractor components to harvesting equipment accessories, we provide reliable solutions to keep your operations running smoothly."/>
          </div>

          <h3><span>Export</span> Division</h3>
          <div className="division-row">
            <DivisionCard image={foodProduct} title="Food Products" description="Indulge in a delectable array of food products sourced from the finest producers worldwide. From exotic spices to gourmet delicacies, we deliver freshness and flavor to your doorstep. Our extensive range includes both perishable and non-perishable items, ensuring a diverse culinary experience for our customers."/>
            <DivisionCard image={perishableProduct} title="Value-Added Perishable Products" description="Elevate your dining experience with our selection of value-added perishable products. From pre-packaged meals to gourmet snacks, we offer convenience without compromising on quality. Explore our range of ready-to-eat options for a delicious and hassle-free culinary journey."/>
            <DivisionCard image={nonPerishableProduct} title="Value-Added Non-Perishable Products" description=" Discover convenience without compromise with our range of value-added non-perishable products. From canned goods to preserved specialties, we bring you premium quality with extended shelf life. Stock up on pantry essentials and gourmet treats for every occasion."/>
          </div>
        </div>


        <div className="service-section">
          <div className="service-section-top">
            <h3>Our Services</h3>
            <p>At Absodok, we are committed to facilitating seamless trade relationships and exceeding customer expectations. With our extensive network, stringent quality standards, and personalized service, we strive to be your trusted partner in global commerce. Explore our comprehensive product offerings and experience the difference with Absodok today!</p>
          </div>
          <div className="service-section-bottom">
            <div className="service-section-bottom-left">
              <NumberedContent counting={1} title="Product Sourcing Services" description="we specialize in tailored product sourcing services worldwide. Leveraging our extensive network and industry expertise, we procure diverse products to meet specific client needs with reliability and efficiency. Our meticulous approach ensures that clients receive high-quality goods that enhance operational efficiency and competitiveness."/>
              <NumberedContent counting={2} title="Import Services" description="Our import solutions streamline procurement from global markets, ensuring timely delivery, regulatory compliance, and cost-effectiveness for raw materials, components, and finished goods. We provide comprehensive support throughout the import process, guaranteeing seamless operations and client satisfaction."/>
              <NumberedContent counting={3} title="Export Services" description="We facilitate efficient global distribution through export services, optimizing market reach and profitability by navigating regulations and logistics for businesses expanding internationally. Our strategic approach enables clients to capitalize on international opportunities, fostering sustainable growth and competitive advantage."/>
            </div>
            <div className="service-section-bottom-right">
              <img src={serviceSectionImage} alt="" />
            </div>
          </div>
        </div>


        <div className="offer-section">
          <p>Our Company operates as an NVOCC (Non-Vessel Operating Common Carrier), enhancing our versatility in handling logistics with expertise.</p>
          <p>Our company solves major problems faced by the clients and manufacturers by meeting their exact requirements making the process of both import and export smooth and a faster experience hence inefficiencies related to time and money. Our company is unique in multiple ways they are we can do the best product sourcing in any market and the best quality shipment with a very reasonable cost which is competitive in the current mark </p>
          <p>We're expanding into the Scrap Marketplace via RecycleInMe.com, showcasing our commitment to sustainability. Future plans include market growth in the Middle East and Europe.</p>
          <p>Additionally, we provide quality inspection and certification services, prioritizing secure transactions with LC at sight for peace of mind.</p>
          <div className="offer-section-row">
            <div className="offer-section-row-data">
              <h3>1,000+</h3>
              <p>Customers</p>
            </div>
            <div className="offer-section-row-data">
              <h3>250+</h3>
              <p>Trades</p>
            </div>
            <div className="offer-section-row-data">
              <h3>98%</h3>
              <p>Respond Time</p>
            </div>
            <div className="offer-section-row-data">
              <h3>100+</h3>
              <p>Products</p>
            </div>
          </div>
        </div>


        <div className="certificate-section">
          <div className="certificate-section-left">
            <h3>Certificate of <span>Authenticity</span></h3>
            <p>we proudly display our certificates of authenticity to underscore our commitment to quality, compliance, and excellence. These certifications serve as a testament to our rigorous standards and dedication to providing our clients with the highest level of trust and reliability in our products and services.</p>
            <p>They reflect our adherence to international regulations, ethical sourcing practices, and stringent quality control measures. By showcasing these certificates, we assure our clients and partners of our unwavering commitment to transparency, integrity, and continuous improvement in all our business operations.</p>
            <Link to="/certificate" className="btn-a blue-btn">View Our Certificates</Link>
          </div>
          <div className="certificate-section-right">
            <img src={certificateSectionImg} alt="" />
          </div>
        </div>

        <div className="home-page-gallery-section">
          <h3>Our <span>Gallery</span></h3>
          <Carousel/>
        </div>

        <OurPricing/>
        
        <div className="book-an-appointment-section">
          <h3>Book An <span>Appointment</span></h3>
          <p>Questions? Need assistance? Our dedicated support team is here to help you every step of the way:</p>
          <AppointmentBooking/>
        </div>

    </div>
  );
}

export default Homepage;
