import React, { useState } from "react";
import "../css/component css/Navbar.css";
import { Link, NavLink } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const logo = require("../images/bg logo.png");

const Navbar: React.FC = () => {
  const [sideMenu, setSideMenu] = useState<boolean>(false);

  const displaySideMenu = (): void => {
    setSideMenu(true);
  };

  const hideSideMenu = (): void => {
    setSideMenu(false);
  };

  return (
    <>
      <div className="navbar">
        <Link to="/" className="navbar-left">
          <img src={logo} alt="logo" />
        </Link>
        <div className="navbar-right">
          <NavLink to="/" className="nav-items" activeClassName="active">
            Home
          </NavLink>
          <NavLink to="/gallery" className="nav-items" activeClassName="active">
            Gallery
          </NavLink>
          <NavLink to="/certificate" className="nav-items" activeClassName="active">
            Certificates
          </NavLink>
          <NavLink to="/product-sourcing" className="nav-items" activeClassName="active">
            Product Sourcing
          </NavLink>
        </div>
        <MenuIcon className="menu-icon" onClick={displaySideMenu} />
        {sideMenu && (
          <div className="side-menu">
            <img src={logo} alt="logo" />
            <NavLink
              
              to="/"
              className="nav-items"
              activeClassName="active"
              onClick={hideSideMenu}
            >
              Home
            </NavLink>
            <NavLink
              to="/gallery"
              className="nav-items"
              activeClassName="active"
              onClick={hideSideMenu}
            >
              Gallery
            </NavLink>
            <NavLink
              to="/certificate"
              className="nav-items"
              activeClassName="active"
              onClick={hideSideMenu}
            >
              Certificates
            </NavLink>
            <NavLink
              to="/product-sourcing"
              className="nav-items"
              activeClassName="active"
              onClick={hideSideMenu}
            >
              Product Sourcing
            </NavLink>
            <CloseIcon className="close-icon" onClick={hideSideMenu} />
          </div>
        )}
      </div>
      <a href="https://wa.me/9444808674" target="_blank" aria-label="whatsapp-link" className="whatsapp-box" rel="noopener noreferrer">
        <WhatsAppIcon className="whatsapp-icon" color="success" />
      </a>
    </>
  );
};

export default Navbar;
