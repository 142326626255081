import React from 'react';
import "../css/page css/ErrorPage.css";
const errorImg = require("../images/404.jpg");

const ErrorPage: React.FC = () => {
  return (
    <div className='error-page'>
      <img src={errorImg.default} alt="Error 404" />
    </div>
  );
}

export default ErrorPage;
