import React from "react";
import "../css/component css/AppointmentBooking.css";
const AppointmentBooking: React.FC = () => {
  return (
    <div className="appointment-booking-section">
      <div className="appointment-booking-box">
        <div className="appointment-booking-box-second-row">
          <h4>Schedule A Call With Just Few Details Within A Second!</h4>
          <a
            href="https://forms.gle/R7byg5vwyMRtXBf86"
            className="btn-a orange-btn"
          >
            Book Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default AppointmentBooking;
