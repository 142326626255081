import React from 'react';
import "../css/component css/CertificateBox.css";

interface CertificateBoxProps {
  image: string;
  name: string;
  url: string;
}

const CertificateBox: React.FC<CertificateBoxProps> = ({ image, name, url }) => {
  return (
    <div className="certificate-box">
      <img src={image} alt={name} />
      <p>{name}</p>
      <a href={url} target='_blank' rel="noopener noreferrer" aria-label='certificate download button' className="btn-a">Download Certificate</a>
    </div>
  );
}

export default CertificateBox;
